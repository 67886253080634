import React from "react";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import classNames from 'classnames';

import NotificationDropdown from "./NotificationDropdown.js";
import UserDropdown from "./UserDropdown.js";

export default function Sidebar() {
  let history = useHistory();
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const { pathname } = useLocation()

  const activePathClasses = `text-indigo-600 hover:text-indigo-400 text-xs uppercase py-3 font-bold block`
  const otherLinksClasses = `text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-semibold block`
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xs bg-white flex flex-wrap items-center justify-between relative md:w-56 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            PRIVJS
          </Link>
          {/* User */}
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    PRIVJS
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={pathname === '/dashboard' ? activePathClasses : otherLinksClasses}
                  to="/dashboard"
                >
                  <i className={classNames("fas fa-tv mr-2 text-sm", {
                    'text-gray-500': pathname !== '/dashboard',
                    'opacity-75': pathname === '/dashboard',
                  })}></i> Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={pathname === '/explore' ? activePathClasses : otherLinksClasses}
                  to="/explore"
                >
                  <i className={classNames("fas fa-search mr-2 text-sm", {
                    'text-gray-500': pathname !== '/explore',
                    'opacity-75': pathname === '/explore',
                  })}></i> Explore
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={pathname === '/account' ? activePathClasses : otherLinksClasses}
                  to="/account"
                >
                  <i className={classNames("fas fa-user-circle mr-2 text-sm", {
                    'text-gray-500': pathname !== '/account',
                    'opacity-75': pathname === '/account',
                  })}></i> My Account
                </Link>
              </li>
              
              <li className="items-center">
                <Link
                  className={pathname === '/upgrade' ? activePathClasses : otherLinksClasses}
                  to="/upgrade"
                >
                  <i className={classNames("fas fa-chart-line mr-2 text-sm", {
                    'text-gray-500': pathname !== '/upgrade',
                    'opacity-75': pathname === '/upgrade',
                  })}></i> Upgrade
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={pathname === '/contact' ? activePathClasses : otherLinksClasses}
                  to="/contact"
                >
                  <i className={classNames("far fa-comment mr-2 text-sm", {
                    'text-gray-500': pathname !== '/contact',
                    'opacity-75': pathname === '/contact',
                  })}></i> Contact Us
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={pathname === '/faq' ? activePathClasses : otherLinksClasses}
                  to="/faq"
                >
                  <i className={classNames("far fa-question-circle mr-2 text-sm", {
                    'text-gray-500': pathname !== '/faq',
                    'opacity-75': pathname === '/faq',
                  })}></i> FAQ
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={pathname === '/roadmap' ? activePathClasses : otherLinksClasses}
                  to="/roadmap"
                >
                  <i className={classNames("fas fa-road mr-2 text-sm", {
                    'text-gray-500': pathname !== '/roadmap',
                    'opacity-75': pathname === '/roadmap',
                  })}></i> Roadmap
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <a
                  className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-semibold block"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault()
                    localStorage.clear()
                    sessionStorage.clear()
                    history.push("/login");
                  }}
                >
                  <i className="fas fa-sign-out-alt text-gray-500 mr-2 text-sm"></i> Log out
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
